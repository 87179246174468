<!--

	Component to handle image display.
	Acts on element resize via https://www.npmjs.com/package/vue-resize-directive
	
	Usage:
		
		<MhImage :imageObject="post.acf.image" mode="img" backgroundPosition="center"></MhImage>	
	
	
	Props:
		
		:imageObject: 		acf-image-object
		:mode				"img" (default)		takes the height the image needs
							"cover"				css-height needs to be set
							"contain"			css-height needs to be set
		:backgroundPosition	"center" (default)	css value for backgroundImage 
		

		
	TODO: 	handle checkSizeBy : height, aktuell wird height verwendet wenn mode=cover, aber so richtig gut ist das nicht
	TODO: 	add lazy loading via IntersectionObserver, https://alligator.io/vuejs/lazy-image/
			function supportsIntersectionObserver() {
				return (
					'IntersectionObserver' in global &&
					'IntersectionObserverEntry' in global &&
					'intersectionRatio' in IntersectionObserverEntry.prototype
				)
			}

	2019-11-20	improvement: devicePixelRatio als multiplicator für das finden der besten größe eingebaut
	2019-11-20	improvement: debug infos besser gestylt
	2019-07-12	improvement: für gifs wird jetzt immer die full-url ausgegeben
	2019-06-04	improvement: docs korrigiert und erweitert
	2019-06-04	improvement: added mode class to elm
	2019-06-03	improvement: removed scoped attribute for easier styling
	
-->

<template>
	<div class="mhImage" :class="getElmClasses()" v-resize:throttle="setElmWidthAndHeight">
		<div 	class="mhImage__image" 
				:class="getImageClasses()" 
				:style="getImageContainerStyles()"></div>
		<div 	class="mhImage__debugInfos" 
				:data-elmWidth="elmWidth" 
				:data-elmHeight="elmHeight" 
				:data-checkSizeBy="checkSizeBy" 
				:data-bestSizeName="bestSizeName">
			<!--
			<pre>{{imageSizes}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src 
	// https://www.npmjs.com/package/vue-resize-directive
	import resize from 'vue-resize-directive'
	
	export default {
		name: 'MhImage',
		components: {},			
		directives: {
			resize,
		},
		props: {
			imageObject: {
				type: [Object, Boolean],
				//required: true,
			},
			mode: {
				type: String,
				default: 'img',
			},
			backgroundPosition: {
				type: String,
				default: 'center',
			},
			debug: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				imageSizes: null,
				elmWidth: null,
				elmHeight: null,
				imageOpacity: 0,
			}
		},
		watch: {
			imageObject ( to, from ){	
				if(to) this.setImageSizes()		
			}
		},		
		computed: {
			checkSizeBy() {
				if( this.mode == 'cover' ){
					return 'height'					
				}else{
					return 'width'
				}
			},
			bestSize() {
				const debug = this.debug
				const mimeType = this.imageObject ? this.imageObject.mime_type : null
				const devicePixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1
				let bestSize = null
				const checkSizeBy = this.checkSizeBy
				const elm = {
					width : this.elmWidth,
					height : this.elmHeight,
				}
				
				let sizes = this._.cloneDeep( this.imageSizes );
					sizes = this._.sortBy(sizes, checkSizeBy)
				let bigEnoughSizes = this._.filter(sizes, size => size[checkSizeBy] > ( elm[checkSizeBy] * devicePixelRatio ) );
				
				// für gifs ist immer "full" die beste größe, da in den
				// anderen (generierten) größen die animation verloren ging
				if( mimeType == 'image/gif' ){
					bigEnoughSizes = this._.filter(sizes, size => size.name === 'full');
				}			
				
				if( bigEnoughSizes.length ){
					bestSize = bigEnoughSizes[0]
				}else{
					bestSize = this._.last( sizes )
				}
				
				if(debug && bestSize){					
					console.log('')
					console.group('bestSize()')
					console.log('elm.width : ', elm.width)
					console.log('devicePixelRatio:', devicePixelRatio)					
					console.log('sizes:', sizes)					
					console.log('bigEnoughSizes:', bigEnoughSizes)
					console.log('bestSize:', bestSize)
					console.log('mimeType:', mimeType)					
					console.groupEnd()
				}
				
				return bestSize
			},
			bestSizeName() {
				return this.bestSize ? this.bestSize.name : false
			}
		},
		methods: {
			/**
			 *  this function is called after resizing
			 *  to set elmWidth and elmHeight
			 */
			setElmWidthAndHeight() {
				this.elmWidth = this.$el.clientWidth
				this.elmHeight = this.$el.clientHeight
			},
			/**
			 *  Set sizes, transforms acf-object to a more readable version:
			 *  
			 *  sizes: {
    		 *		"thumbnail": "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-100x100.jpg",
    		 *    	"thumbnail-width": 100,
    		 *    	"thumbnail-height": 100
    		 *  }
			 *
			 * 	sizes: {
			 * 		thumbnail: {
			 * 			width: 100,
			 * 			height: 100
			 * 		}
			 * 	}
			 *  
			 */			
			setImageSizes() {
				const imageObject = this.imageObject
				let newSizes = {}
				let newSizesArr = []
				
				if(imageObject){
					const sizes = imageObject.sizes
				
					for (let elem in sizes) {
						let key   = elem
						let value = sizes[elem]
						
						// detect if key is a size name, e.g. "thumbnail"
						if( sizes[key+'-width'] ){
							newSizes[key] = {
								url : value,
								width : sizes[key+'-width'],
								height : sizes[key+'-height'],
							}
							newSizesArr.push({
								name : key,
								url : value,
								width : sizes[key+'-width'],
								height : sizes[key+'-height'],
							})
						}
					}
					
					//this.imageSizes = newSizes
					this.imageSizes = newSizesArr
				}
			},
			
			getElmClasses() {
				return 'mhImage--mode-' + this.mode
			},
			getImageClasses() {
				return 'mhImage__image--mode-' + this.mode
			},
			getImageContainerStyles() {
				if( this.imageObject && this.imageSizes ){
					const width    = this.imageObject.width
					const height   = this.imageObject.height
					const imageSrc = this.bestSize.url + '?1'
					const backgroundPosition = this.backgroundPosition		

					let styles = {
						backgroundImage : 'url('+imageSrc+')',
						paddingBottom : (height/width) * 100 + '%',
						backgroundPosition : backgroundPosition,
					}
					
					if( this.mode == 'cover' ){
						styles.paddingBottom = false
					} 
					if( this.mode == 'contain' ){
						styles.paddingBottom = false
					} 
					
					return styles;	
				}
			},									
		},
		created() {},
		mounted() {	
			this.setImageSizes()
			this.setElmWidthAndHeight()		
			//setTimeout( () => {
			//}, 25)
		},
	}
</script>

<style lang="less">
	.mhImage {
		position: relative;
	}
	.mhImage__debugInfos {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		overflow: auto;
		
		// label with debug infos
		[showborders4] &:before {
			position: absolute;
			top: 0; left: 0;
			content: attr(data-elmWidth) " × " attr(data-elmHeight) "\a" "checkBy: " attr(data-checkSizeBy) "\a" "bestSize: " attr(data-bestSizeName) ;
			white-space: pre-line;
			background-color: yellow;
			color: fade(black, 75);
			padding: 0.3em;
			font-size: 11px;
			line-height: 1.2em;
		}
		
		pre {
			background-color: fade(yellow, 50);			
		}
	}
	.mhImage__image {
		width: 100%;
		height: inherit;
		background-repeat: no-repeat;
		background-position: center;
	}
	.mhImage__image--mode-img {
		background-size: cover;		
	}
	.mhImage__image--mode-cover {
		position: absolute;
		top: 0; left: 0;
		height: 100%; 
		background-size: cover;
	}
	.mhImage__image--mode-contain {
		position: absolute;
		top: 0; left: 0;
		height: 100%; 
		background-size: contain;
	}
</style>
