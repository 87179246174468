<template>
	<div class="devInfos">
		<div class="devInfos__row" :class="{'devInfos__row--isHidden' : !store.barIsVisible}">
			<div class="devInfos__col devInfos__col--left">
				{{$route.name}} •
				{{windowInnerWidth}}px • 
				{{$mq}}
			</div>
			<div class="devInfos__col devInfos__col--right">
				<input class="devInfos__checkbox" type="checkbox" v-model="store.showBorders[index]" v-for="(checkbox, index) in store.showBorders" :key="index" />
			</div>
		</div>
		<div class="devInfos__toggleRowBtn" @click="store.barIsVisible = !store.barIsVisible" :class="{'devInfos__toggleRowBtn--isActive' : !store.barIsVisible}">
			<div>
				<span></span>
				<span></span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DevInfos',
		components: {},
		props: {},
		data() {
			return {
				windowInnerWidth : window.innerWidth,
				bodyElm : null,
				store : {	// this object is watched and saved to localStorage on change
					barIsVisible: false,
					showBorders: [
						false,
						false,
						false,
						false,
						false,
					],
				}
			}
		},
		watch: {
			'store.showBorders' ( to, from ){
				this.setBorderAttributes()
			},
			store: {
				handler: function( to, from ) {
					const parsed = JSON.stringify(this.store);
					localStorage.setItem('MhDevInfos', parsed);			
				},
				deep: true,
			}
		},
		methods: {
			// setWindowInnerWidth
			bindResize () {
				window.onresize = (event) => {
					this.windowInnerWidth = window.innerWidth
				};
			},
			setBorderAttributes () {
				for (let i = 0; i < this.store.showBorders.length; i++) {
					let value = this.store.showBorders[i]
					let index = i+1
					if(value){
						this.bodyElm.setAttribute('showBorders' + index, '')
					}else{
						this.bodyElm.removeAttribute('showBorders' + index)
					}
				}
			},
			toggleDevInfosBar () {

			},
			bindKeydowns () { // bind:   keydown events, toggle checkboxes via keyboard, etc
				let prevKeycode = null
				let prevShowBorders = null

				document.addEventListener("keydown", (e) => {
					var keyCode          = e.keyCode;
					var activeElmTagName = document.activeElement.tagName.toLowerCase()
					var validKeyCodes    = [49, 50, 51, 52, 53] // 49 means 1, 50 means 2 ...
					var checkboxIndex	 = keyCode - 49

					//console.log(keyCode)

					// not if input or textarea is focused
					if(activeElmTagName == 'input' || activeElmTagName == 'textarea') return

					//prevKeycode =

					// w: toggle borders
					if(keyCode == 87){

						if(prevKeycode == 87){
							//console.log('w + w')
							this.store.showBorders = prevShowBorders
							prevShowBorders = this.store.showBorders
							keyCode = false
						}else{
							//console.log('w')
							prevShowBorders = this.store.showBorders
							this.store.showBorders = new Array(this.store.showBorders.length).fill(false)
						}

						e.preventDefault() // needed for ff to prevent triggering site search
					}

					// h: toggle devInfos Bar
					if(keyCode == 72){
						this.store.barIsVisible = !this.store.barIsVisible
						e.preventDefault() // needed for ff to prevent triggering site search
					}

					// esc: uncheck all checkboxes
					if(keyCode == 27){
						this.store.showBorders = new Array( this.store.showBorders.length ).fill( false )
					}

					// 1-5: toggle checkbox by index
					if(validKeyCodes.indexOf(keyCode) > -1){
						var clone = this.store.showBorders.slice(0); // use clone to force reactivity
						clone[checkboxIndex] = !clone[checkboxIndex]
						this.store.showBorders = clone
						e.preventDefault() // needed for ff to prevent triggering site search
					}

					prevKeycode = keyCode

				}, 	false);
			},
		},
		created() {
			this.bodyElm = document.getElementsByTagName("body")[0];
			this.bindKeydowns()
			this.bindResize()
		},
		mounted() {

			// get store data from localStorage if there is any
			if( localStorage.getItem('MhDevInfos') ) {
				try {
					this.store = JSON.parse( localStorage.getItem( 'MhDevInfos' ) );
				} catch(e) {
					localStorage.removeItem( 'MhDevInfos' );
				}
			}

			// set border attr inital
			this.setBorderAttributes()
		},
	}
</script>

<style lang="less" scoped>
	.devInfos {
		position: fixed;
		left: 0; right: 0; bottom: 0em;
		display: flex;
		justify-content: space-between;
		z-index: 1000;
		font-size: 13px;
		line-height: 13px;

		&__row {
			transition: all 0.5s ease;
			position: relative;
			display: flex;
			justify-content: space-between;
			flex-grow: 1;
		}
		&__row--isHidden {
			transform: translateX(110%);
		}
		&__col {
			padding: 0.25em 0.35em;
			flex-grow: 0;
			background-color: yellow;
		}

		&__toggleRowBtn {
			@width: 1.43em;

			position: relative;
			display: flex;
			flex-grow: 0;
			align-items: stretch;
			background-color: darken(yellow, 10);
			background-color: yellow;
			width: @width;
			cursor: pointer;

			div {
				//background-color: black;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				//justify-content: space-between;
				border: 0.2em solid transparent;
				//border-top-width: 0.35em;
				//border-bottom-width: 0.35em;
			}

			span {
				transition: all 0.2s ease;
				display: block;
				background-color: black;
				height: 1px;
				width: 100%;
				margin: 0;
			}
			span:nth-child(1) {
				transform: rotate(45deg) translateY(50%);
			}
			span:nth-child(2) {
				transform: rotate(-45deg) translateY(-50%);
			}
		}
		&__toggleRowBtn--isActive {
			span:nth-child(1),
			span:nth-child(2),
			span:nth-child(3) {
				margin: 1px 0;
				transform: none;
			}
		}

		&__checkbox {
			& + & {
				margin-left: 0.25em;
			}
		}
	}
</style>
