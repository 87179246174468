export default {
	"environments" : {
		"localDev" : { 										// the name of the environment
			"hostname" : "localhost", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://ies.local:8080", // the url to the wordpress restApi
		},
		"localDev2" : { 							// the name of the environment
			"hostname" : "192.168", 					// the hostname the app is served from, this detects the environment
			"restBase" : "http://ies.local:8080", // the url to the wordpress restApi
		},
		"localBuild" : {
			"hostname" : "ies.local",
			"restBase" : "http://ies.local:8080",
		},
		"onlineDev" : {
			"hostname" : "dev.urban-issues.net",
			"restBase" : "http://dev.urban-issues.net",
		},
		"onlineLive" : {
			"hostname" : "urban-issues.net",
			"restBase" : "https://urban-issues.net",
		},
	}
}
