<template>
	<div class="mhScroller" @scroll="handleScroll()">
		<div class="mhScroller__content">
			<slot></slot>
		</div>
		<div class="mhScroller__track">
			<div class="mhScroller__thumb"></div>
		</div>	
	</div>
</template>

<script>
	// @ is an alias to /src 
	//import Spinner from '@/components/Spinner.vue'
	//import { EventBus } from '@/event-bus.js'

	export default {
		name: 'MhScroller',
		components: {},			
		props: {
			/*
			isLoading : {
				type: Boolean,
				default: false,
			}
			*/
		},
		data() {
			return {
				isOnScrollStart : true,
				isScrolling : null,
				wrapperHeight : null,
				innerHeight : null,
				heightInPercentage : null,
				contentContainerEl : null,
				thumbEl : null,
			}
		},
		watch: {},
		computed: {},
		methods: {
			handleScroll(){

				if(this.isOnScrollStart) {
					this.onScrollStart()
					this.isOnScrollStart = false
				}
				
				this.onScroll()
				
				// Clear our timeout throughout the scroll
				window.clearTimeout( this.isScrolling );

				// Set a timeout to run after scrolling ends
				this.isScrolling = setTimeout(() => {

					this.onScrollStop()
					this.isOnScrollStart = true

				}, 100);
			},
			onScrollStart(){
				console.log('')
				console.log( 'onScrollStart' );
				
				this.wrapperHeight      = Math.round( parseFloat( window.getComputedStyle(this.$el).height ) )
				this.innerHeight        = Math.round( parseFloat( window.getComputedStyle(this.contentContainerEl).height ) )
				this.heightInPercentage = Math.round( (this.wrapperHeight / this.innerHeight) * 100 )
				
				console.log( '	wrapperHeight:        ' + this.wrapperHeight );
				console.log( '	innerHeight:          ' + this.innerHeight );
				console.log( '	heightInPercentage:   ' + this.heightInPercentage );
				
				this.thumbEl.style.height = this.heightInPercentage + '%'
			},
			onScroll(){

				const scrollTop = this.$el.scrollTop
				const scrolledInPercentage = Math.round( (scrollTop / (this.innerHeight-this.wrapperHeight) ) * 100 )
				const thumbTop = Math.round( (100-this.heightInPercentage) * (scrolledInPercentage / 100) )
				
				console.log('onScroll')
				console.log('	scrollTop:            ' + scrollTop)
				console.log('	scrolledInPercentage: ' + scrolledInPercentage)
				console.log('	thumbTop:             ' + thumbTop)

				this.thumbEl.style.top = thumbTop + '%'
			},
			onScrollStop(){
				console.log('onScrollStop')								
			},
		},
		created() {},
		mounted() {
			this.contentContainerEl = this.$el.querySelector('.mhScroller__content')
			this.thumbEl = this.$el.querySelector('.mhScroller__thumb')
		},
	}
</script>

<style lang="less">
	.mhScroller {
		height: inherit;
		overflow: auto;
		overflow-x: auto;	
		background-color: fade(red, 20);
		
		//-webkit-overflow-scrolling: touch; // ansonsten ist das ux absolut steif. wieder was gelernt :)
		//scrollbar-width: none; /* Firefox */
		//-ms-overflow-style: none;  /* IE 10+ */		
		//&::-webkit-scrollbar { width: 0px; }
	}
	.mhScroller__track {
		position: absolute;
		top: 0; right: 0; bottom: 0;
		width: 10px;
		//background-color: red;
		display: flex;
		justify-content: center;
		pointer-events: none;
		
		display: none;
	}
	.mhScroller__thumb {
		position: absolute;
		background-color: fade(black, 75);
		height: 20%;
		width: 5px;
		margin-top: 4px;
		//border-top: 3px solid red;
		//border-left: 2px solid transparent;
		//border-right: 2px solid transparent;
		border-radius: 5px;
		pointer-events: none;
	}
</style>
