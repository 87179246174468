<template>
	<div class="view" :class="{'view--isLoading' : viewIsLoading, 'view--loaded' : !viewIsLoading}">
		
		<Spinner class="view__loadingSpinner"></Spinner>
		
		<transition name="fade" appear>
			<div class="view__inner" :style="wrapperElmStyles">
				<slot></slot>				
			</div>
		</transition>
		
	</div>
</template>

<script>
	// @ is an alias to /src 
	import Spinner from '@/components/Spinner.vue'
	import { EventBus } from '@/event-bus.js'

	export default {
		name: 'MhView',
		components: {
			Spinner
		},			
		props: {
			isLoading : {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				viewIsLoading : true,
				//appHeaderHeight : 0 // wird via EventBus.$on('appHeaderResized') gesetzt
			}
		},
		watch: {
			'$props.isLoading' ( to, from ){				
				// short delay to let the browser render the content
				// and fixes some backstopJs issues
				if( to === false ){
					setTimeout( () => {
						this.viewIsLoading = false						
					}, 100) 
				}
			}
		},
		computed: {
			wrapperElmStyles() {
				//const appHeaderHeight = this.appHeaderHeight
				const appHeaderHeight = this.$root.appHeaderHeight
				const elmHeight = 'calc(100vh - ' + appHeaderHeight + 'px + 1px)'
				
				if( !this.$root.isSmallDevice ){
					return {
						transition : 'all 0.1s linear',
						minHeight  : elmHeight,
					}
				}else{
					return {}
				}

			},
		},
		methods: {},
		created() {
			this.viewIsLoading = this.isLoading
		},
		mounted() {
			
			// post container ist zusammen mit der header height 100vh
			// wenn sich die header höhe ändert, wird die neue height 
			// hier via event empfangen und dient der berechnung der 
			// höhe des post containers
			/*
			EventBus.$on('appHeaderResized', elmHeight => {
				this.appHeaderHeight = elmHeight				
				//console.log( elmHeight )
			})	
			*/
			
		},
	}
</script>

<style lang="less" scoped>
	@import "../../less/includes/grid.include.less";
	
	@transitionDuration: 0.15s;
	
	.view {
		//background-color: fade(red, 10);
		position: relative;
		display: flex;
		flex-direction: column;
		
		& > * {
			transition: all @transitionDuration ease-out 0.2s;
		}
		&--isLoading > * {
			opacity: 0;
		}		
		&--loaded > * {
			//transition-delay: ;
			padding-top: 0em;
		}

		&__inner {
			//background-color: fade(red, 10);
			position: relative;
			flex-grow: 1;
			display: flex;
			flex-direction: column;	
			min-height: 100vh; // 2018-20-23 damit sollte der footer immer außerhalb sein					
		}
		&__loadingSpinner {
			//outline: 1px solid red;
			transition: all @transitionDuration ease;
			position: absolute;
			top: 50vh; left: 50vw;
			transform: translateY(-100%) translateX(-50%);
			height: 3em; width: 3em;
			
			.view--isLoading & {
				opacity: 1;
			} 
			.view--loaded & {
				opacity: 0; 
			} 
		}				
	
		@media @mediaQuery__sm {
			min-height: auto;
		}
	
	}

	.fade-enter-active, .fade-leave-active {
		transition-property: opacity;
		transition-duration: @transitionDuration;
	}
	.fade-enter-active {
		transition-delay: @transitionDuration;
	}
	.fade-enter, .fade-leave-active {
		opacity: 0;
	}	
</style>
