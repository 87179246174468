import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=254a6d0e&scoped=true&"
import script from "./Spinner.vue?vue&type=script&lang=js&"
export * from "./Spinner.vue?vue&type=script&lang=js&"
import style0 from "./Spinner.vue?vue&type=style&index=0&id=254a6d0e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254a6d0e",
  null
  
)

component.options.__file = "Spinner.vue"
export default component.exports