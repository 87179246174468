<template>
	<div class="MhValidator" 
		@mousedown="mouseDownHandler( $event )"
		@mousemove="mouseMoveHandler( $event )"
		@mouseup="mouseUpHandler( $event )"
	>
		MhValidator<br />
	</div>
</template>

<script>
	// @ is an alias to /src 
	//import DevInfos from '@/components/DevInfos.vue'
	
	//import JQuery from 'jquery'
	//let $ = JQuery
	
	//import vueJquery from 'vue-jquery'
	export default {
		name: 'MhValidator',
		components: {
		},			
		props: {
			//imageObject: [Object, Boolean],
		},
		data() {
			return {
				debugLog : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {											
				},
				deep: true,
			}			
		},		
		computed: {},
		methods: {
			_queryElms( queryObj ){
				const debugLog = false
				
				// build the notString, e.g. ':not(.blue):not('.header')'
				let notString = ''
				if( this._.isArray( queryObj.ignoreSelectors ) ){
					this._.forEach( queryObj.ignoreSelectors, ( selector ) => {
						notString = notString + ':not(' + selector + ')'
					})
				}
				else if( this._.isString( queryObj.ignoreSelectors ) ){
					notString = queryObj.ignoreSelectors
				}				
				//console.log( 'notString:', notString );
				
				let allEls = document.querySelectorAll( queryObj.selector + notString )
				
				if( debugLog ) console.log( 'allEls.length:', allEls.length );
				
				let filteredElms = this._.filter( allEls , (el) => {
					var element       = el
					var elementStyle  = element.style;
					var computedStyle = window.getComputedStyle(el, null);
					var matches       = false
					
					if( queryObj.styleType == 'computed' ){
						for (var prop in elementStyle) {
							if (elementStyle.hasOwnProperty(prop)) {
								const key = prop
								const value = computedStyle[prop]
								
								if( key.match( queryObj.cssKey ) ) {								
									if( debugLog ){
										console.log( '' );
										console.log( 'key:', key );
										console.log( 'value:', value );
									}
									if( value.match( queryObj.cssValue ) ){
										matches = true
									}
								}
							}
						}
					}
					if( queryObj.styleType == 'element' ){
						this._.forEach( elementStyle, (key) => {
							const value = elementStyle[key]
							
							if( key.match( queryObj.cssKey ) ){ 
								if( debugLog ){
									console.log( '' );
									console.log( 'key:', key );
									console.log( 'value:', value );
								}
								if( value.match( queryObj.cssValue ) ){
									matches = true
								}
							}
						})
					}
					
					return matches
				})
								
				if( debugLog ) console.log( filteredElms );
				
				return filteredElms
			},
			checkScrollElms(){
				const debugLog = true
				
				const elms = this._queryElms({
					selector : '*', // valid selector
					ignoreSelectors : ['.mhImage__debugInfos', '.MhValidator'], // string | array
					cssKey : /overflowX|overflowY/, // regEx
					cssValue : /auto|scroll/, // regEx
					styleType : 'computed', // element | computed
				})
				
				if( debugLog ) console.log( '' )
				if( debugLog ) console.group( 'checkScrollElms()', elms.length )
				this._.forEach( elms, (el) => {
					if( debugLog ) {
						console.log( el )
						console.log( '	overflowX:', window.getComputedStyle(el, null).overflowX )
						console.log( '	overflowY:', window.getComputedStyle(el, null).overflowY )
					}
				})
				if( debugLog ) console.groupEnd( '' )
			},
			
			mouseDownHandler( e ){
				//console.log('down')
			},
			mouseMoveHandler( e ){
				//console.log('move')				
			},
			mouseUpHandler( e ){
				//console.log('up')
			},
		},
		created() {
			setTimeout(()=>{		
				this.checkScrollElms()		
			}, 1000)
		},
		mounted() {},
	}
</script>

<style lang="less" scoped>
	.MhValidator {
		display: none !important;
		
		position: fixed;
		top: 0; left: 0;
		background-color: lightgreen;
		padding: 0.5em;
		z-index: 100;
		max-height: 100vh;
		overflow: auto;
	}
</style>
