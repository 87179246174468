<template>
	<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="black">
		<g fill="none" fill-rule="evenodd">
			<g transform="translate(1 1)" stroke-width="2">
				<circle stroke-opacity=".35" cx="18" cy="18" r="18"/>
				<path d="M36 18c0-9.94-8.06-18-18-18">
					<animateTransform
						attributeName="transform"
						type="rotate"
						from="0 18 18"
						to="360 18 18"
						dur="1s"
						repeatCount="indefinite"/>
				</path>
			</g>
		</g>
	</svg>
</template>

<script>
	// @ is an alias to /src 
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'Spinner',
		components: {
		},			
		props: {
			//imageObject: [Object, Boolean],
		},
		data() {
			return {
				strokeWidth : 5,
				width: 138,
				height: 138,								
				color: 'red',
			}
		},
		methods: {						
			getViewbox() {
				return '0 0 38 38'
			},
			getStyles() {
				return {
					//width : '5em',
					//height : '5em',
				}
			}
		},
		created() {
			
		},
		mounted() {			
		},
	}
</script>

<style lang="less" scoped>
	.spinner {
		outline: 1px solid red;
	}
</style>
