<template>
	<div class="app handleBold" @click="delegateHtmlLinksToRouter">
		
		<a 	class="splashScreen" 
			role="button"
			:class="{
				'splashScreen--hasTransition' : splashScreenHasTransition,
				'splashScreen--isHidden' : splashScreenIsHidden,
			}" 
			@click="clickSplashScreen()">
			<template v-if="acfOptions">
				<MhImage 	class="splashScreen__image" 
							:imageObject="acfOptions.introImage" 
							mode="cover">
				</MhImage>			
			</template>
			<h4 class="splashScreen__title hSpace hSpace--big vSpace vSpace--default">
				<span class="splashScreen__titleText font font--sizeHuge">Institut für Entwerfen und Städtebau</span><br />
				<span class="splashScreen__titleDot font font--sizeHuge"></span> 
				<span class="splashScreen__titleText font font--sizeHuge">Städtebauliches Entwerfen</span>
			</h4>
		</a>

		<div class="app__header border border--bottom" v-show-slide="headerIsVisible" v-resize:throttle="appHeaderResized" v-if="!$root.isSmallDevice">
			<div class="app__headerInner">
				<div class="appTitle hSpace hSpace--big vSpace vSpace--appHeader">
					<router-link class="routerLink" 
						:to="'/'+$root.getCurrentLang()+'/'">
						<span class="appTitle__text appTitle__text--full">
							Institut für Entwerfen und Städtebau
						</span>
						<span class="appTitle__text appTitle__text--short">
							IES
						</span>
						<span class="noBreak">
							<span class="appTitle__dot"></span>
							<span class="appTitle__text appTitle__text--full">
								Städtebauliches Entwerfen
							</span>
							<span class="appTitle__text appTitle__text--short">
								SE
							</span>
						</span>
					</router-link>	
				</div>
				
				<MainNav></MainNav>			
			</div>
		</div>	

		<MobileNav v-if="$root.isSmallDevice"></MobileNav>
		
		<a class="app__toggleHeaderBtn hSpace--big hSpace--notLeft vSpace vSpace--appHeader" @click="clickToggleHeader()" role="button">
			<div>
				<span></span>
				<span></span>
				<span></span>				
			</div>
		</a>	
		
		<div class="app__body">			
			<div class="app__content">
				<router-view :key="$route.path" />				
			</div>
			<div class="app__footer">
				<div class="row row--red">
					<template v-if="acfOptions">
						<div class="hSpace hSpace--big vSpace vSpace--default col col--5of12 col--sm-12of12" v-html="$root.getTranslatedAcfValue(acfOptions, 'footerFirstCol')"></div>
						<div class="hSpace hSpace--big vSpace vSpace--default col col--3of12 col--sm-12of12" v-html="$root.getTranslatedAcfValue(acfOptions, 'footerSecondCol')"></div>
						<div class="hSpace hSpace--big vSpace vSpace--default col col--2of12 col--sm-12of12" v-html="$root.getTranslatedAcfValue(acfOptions, 'footerThirdCol')"></div>
						<div class="hSpace hSpace--big vSpace vSpace--default col col--2of12 col--sm-12of12 luhLogoCol">
							<div class="luhLogoCol__logo"></div>
						</div>
					</template>
				</div>
			</div>
		</div>
		
		<template v-if="
			getHostname() == 'localhost' || 
			getHostname() == 'ies.local' ||
			getHostname() == 'dev.urban-issues.net' 
		">
			<DevInfos></DevInfos>
		</template>
		
		<template v-if="
			getHostname() == 'localhost' || 
			getHostname() == 'ies.local' ||
			getHostname() == 'dev.urban-issues.net' 
		">
			<MhValidator></MhValidator>
		</template>
		
	</div>
</template>

<script>
	// @ is an alias to /src 
	import DevInfos from '@/components/DevInfos/DevInfos.vue'
	import MainNav from '@/components/MainNav.vue'
	import MobileNav from '@/components/MobileNav.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhScroller from '@/components/MhScroller/MhScroller.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import { EventBus } from '@/event-bus.js'
	import resize from 'vue-resize-directive' 
	import MhValidator from '@/components/MhValidator/MhValidator.vue'

	export default {
		name: 'app',
		components: {
			DevInfos,
			MainNav,
			MobileNav,
			MhImage,
			MhScroller,
			MhValidator,
		},
		directives: {
			resize,
		},		
		mixins: [ RestHandler ],
		data() {
			return {
				acfOptions : null,
				headerIsVisible : false,
				mobileNavIsVisible : false,
				location : null, 
				splashScreenIsVisible : null,
				splashScreenHasTransition : false,
				translations: {
					'Alle' 	                        : 'All',
					'Suche' 	                    : 'Search',
					'Suchergebnisse für'            : 'Search results for',
					
					'Professor'                     : 'Professor',
					'Wissenschaftliche Mitarbeit'   : 'Assistant Professor',
					'Lehrbeauftragte'               : 'Lecturers',
					'Studentische Mitarbeit'        : 'Student Assistant',
					'Ehemalige'                     : 'Former Colleagues',
					'Sekretariat'                   : 'Office',
					
					'Verwandte Lehrveranstaltungen' : 'Related classes',
					'Projekte' 		                : 'Projects',
					'Verwandte Projekte'            : 'Related projects',
					
					'Aufgabenstellung'              : 'Brief',
					'Lehre'                         : 'Programm',
					'Projekt'                       : 'Project',
					'Forschung'                     : 'Research',
					'Publikation'                   : 'Publication',
					'Publikationen'                 : 'Publications',
					'Team' 		                    : 'Team',
					'Seite'                         : 'Page',
					
					'Semester'                      : 'Term',
					'Modul' 	                    : 'Module',
					'Thema'                         : 'Topic',
				}
			}
		},
		computed: {	
			splashScreenIsHidden() {
				let isHidden
				
				if( this.$route.name == 'HomeView' && this.splashScreenIsVisible ){
					isHidden = false
				}else{
					isHidden = true
				}
				
				if( this.splashScreenIsVisible ) isHidden = false
				
				return isHidden
			},
		},
		methods: {
			getHostname(){	// for hidding the devInfos on live
				return window.location.hostname
			},			
			clickSplashScreen(){
				// store was clicked in sessionStorage, that the
				// user just sees the splashScreen only once per session 
				sessionStorage.setItem('splashScreenWasClicked', true);
				
				// enable transition
				this.splashScreenHasTransition = true;
				// slide out
				setTimeout( () => {
					this.splashScreenIsVisible = false;
				}, 50)				
				
				// im safari kommt es zu einem flackern der schrift,
				// vermutlich ausgelöst durch den mix-blend-mode.
				// deswegen wird das element per display:none komplett
				// vom rendering/composition ausgeschlossen.
				const element = document.querySelector('.splashScreen')
				setTimeout( () => {
					element.style.display = 'none';
				}, 850)
			},
			clickToggleHeader(){
				this.headerIsVisible = !this.headerIsVisible
				this.mobileNavIsVisible = !this.mobileNavIsVisible
			},
			appHeaderResized( elm ){
				const elmHeight = parseFloat( window.getComputedStyle( elm ).height )
				//console.log('Emit: appHeaderResized: ' + elmHeight)
				EventBus.$emit('appHeaderResized', elmHeight)
			},
			/**
			 *  This handles clicking on html-links from rest-api 
			 *  and performs a router push if the urls are right
			 *  Taken from: https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
			 */
			delegateHtmlLinksToRouter ($event) {
				
				const debugLog = false
				
				if(debugLog) console.log('delegateHtmlLinksToRouter()')
				if(debugLog) console.log($event)
				
				const { target } = $event
				// handle only links that occur inside the component and do not reference external resources
				//if (target && target.matches(".dynamic-content a:not([href*='://'])") && target.href) {
				if (target && target.matches("a:not([href*='.pdf'])") && target.href) {
				//if (target && target.href) {
					
					//const resolved = this.$router.resolve(target.href)
					
					if(debugLog) console.log('link matches')
					//if(debugLog) console.log(resolved)

					// some sanity checks taken from vue-router:
					// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
					const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = $event
					// don't handle with control keys
					if (metaKey || altKey || ctrlKey || shiftKey) return
					// don't handle when preventDefault called
					if (defaultPrevented) return
					// don't handle right clicks
					if (button !== undefined && button !== 0) return
					// don't handle if `target="_blank"`
					if (target && target.getAttribute) {
						const linkTarget = target.getAttribute('target')
						if (/\b_blank\b/i.test(linkTarget)) return
					}
					// don't handle same page links/anchors
					const url = new URL(target.href)
					const to = url.pathname
					if (window.location.pathname !== to && $event.preventDefault) {
						$event.preventDefault()
						this.$router.push(to)
					}
				}else{
					if(debugLog) console.log('link doesnt match')
				}
				
				//$event.preventDefault()
			}		
		},
		mounted() {
			this.location = location	

			// 2019-09-03  	Versuch bei Google besser gefunden zu werden:
			// 				Das Menü ist jetzt immer sichtbar
			if( !this.$root.isSmallDevice ) this.clickToggleHeader()

			// check sessionStorage	if splashScreen was clicked before	
			/*
			if( sessionStorage.getItem('splashScreenWasClicked') === 'true' ) {
				this.splashScreenIsVisible = false
			}else{
				this.splashScreenIsVisible = true
			}
			*/
			
			// wunsch von ies: splashScreen soll nach dem reload wieder sichtbar sein
			// 2019-07-13  splashScreen ist nur noch auf Home sichtbar
			if( this.$route.name == 'HomeView' ){
				this.splashScreenIsVisible = true				
			}
			
			// fetch acfOptions
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					this.acfOptions = response.data.result
					
					// hier wird aus allen introImages eins zufällig ausgewählt
					this.acfOptions.introImage = this._.sample( this.acfOptions.introImages )
					
					// um das layout handeln zu können sind die beiden
					// ersten menüpunkte, news + info, fest ins system integriert
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "Info",
						title__en: "Info",
						type: "toggleSecondRow",
						page: false
					})
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "News",
						title__en: "News",
						type: "homeView",
						page: false
					})

					this.$set(this.acfOptions, 'translations2', this.translations )
				},
			})			
			
			// after route change: scroll app__body to top 
			const appBody = document.querySelector('.app__body')
			this.$router.afterEach( (to, from) => {
				appBody.scrollTo(0, 0)
			})
		}
	}
</script>

<style lang="less"> 
	@import "less/includes/normalize.include.less";
	@import "less/includes/grid.include.less";
	@import "less/fonts.less";
	
	//@swatch__green: rgb(0, 205, 75);
	//@swatch__red: rgb(240, 60, 60);
	//@swatch__yellow: rgb(255, 255, 0);
	@swatch__green: #45e37f;
	@swatch__red: #f6462a;
	@swatch__yellow: #fff42d;
	
	.font--sans {
		font-family: 'Favorit', sans-serif;
	}
	.font--italic {
		font-style: italic;
	}
	.font--uppercase {
		text-transform: uppercase;
		//font-size: 0.85em;
		letter-spacing: 0.08em;
	}
	.font--sizeHuge {
		font-size: 28px * 1.24;
		line-height: 31px  * 1.24;
		letter-spacing: -0.025em;
		
		@media @mediaQuery__sm {
			font-size: 24px;
			line-height: 25px;
		}
	}
	.font--sizeBig {
		font-size: 19px * 1.24;
		line-height: 22px * 1.24;
		
		@media @mediaQuery__sm { 
			font-size: 19px * 1.24;
			line-height: 22px * 1.1;
		}		
	}
	.font--sizeDefault {
		font-size: 13px * 1.24 * 1.1;
		line-height: 16px * 1.24 * 1.1;
		letter-spacing: 0.01em;
		
		@media @mediaQuery__sm { 
			font-size: 13px * 1.1;
			line-height: 16px * 1.1;
		}
	}	
	.font--sizeSmall {
		font-size: 10px * 1.24;
		line-height: 12px * 1.24;
		letter-spacing: 0.01em;
		
		@media @mediaQuery__sm { 
			font-size: 10px * 1.1;
			line-height: 12px * 1.1;
		}		
	}	
	.font--sizeMini {
		font-size: 7px * 1.24;
		line-height: 10px * 1.24;
		letter-spacing: 0.01em;
	}	
	
	body {
		.font--sans;
		.font--sizeDefault;
		
		// neben dem besseren schriftrendering löst das im safari außerdem das flackern des
		// headers wenn ein neues template geladen wird und manchmal wenn text gehovert wird
		-webkit-font-smoothing: antialiased;	
		-moz-osx-font-smoothing: grayscale;
	}
	h1, h2, h3, h4, h5 {
		font-size: inherit;
		line-height: inherit;
		font-weight: normal;
		margin: 0;
	}
	a {
		cursor: pointer;
		
		.app__content &:not(.noArrow),
		.app__footer &:not(.noArrow) {
			color: inherit;
			text-decoration: none;
			white-space: nowrap; // prevent arrow-text-breaking in app__footer
			
			&:before {
				content: "→";
				display: inline-block;
				padding-right: 0.25em;
			}			
			&:hover {
				text-decoration: underline;
			}
		}
	}
	pre {
		@color: black;
		
		position: relative;
		margin-top: 1em;
		outline: 1px solid fade(@color, 25);
		padding: 0.5em;
		background-color: fade(@color, 5);
		overflow: auto;
		color: fade(@color, 65);
		tab-size: 4;
		white-space: pre;
		
		// label
		&[data-name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(data-name);
			font-family: inherit;
		}		
		
		&:first-child {
			margin-top: 0em;
		}
	}
	hr {
		border: none;
		border-top: 1px solid;
		margin: 0.5em 0;
		opacity: 0.25;
	}
	ol {
		@li__indent: 1.5em;
		
		.app__content & {
			counter-reset: section;
			list-style: none;
			
			li {
				position: relative;
				padding-left: @li__indent;
			}
			li:before {
				position: absolute;
				top: 0; left: 0;
				
				counter-increment: section;
				content: counters(section,".") ".";
			}
		}
	}
	ul {
		@li__indent: 1.5em;
		
		.app__content & {
			//background-color: fade( red, 20 );
			list-style: none;
			
			li {
				position: relative;
				padding-left: @li__indent;
			}
			li:before {
				position: absolute;
				top: 0; left: 0;
				
				content: "•";
			}
		}
	}

	// debug: show headline hierachy
	[showborders1] {
		h1 {
			&:before {
				content: "h1";
				color: lighten( red, 20 );
			}
		}
		h2 {
			&:before {
				content: "h2";
				color: lighten( red, 20 );
			}
		}
		h3 {
			&:before {
				content: "h3";
				color: lighten( red, 30 );
			}
		}		
		h4 {
			&:before {
				content: "h4";
				color: lighten( red, 30 );
			}
		}		
	}

	.hideScrollbars {		
		-webkit-overflow-scrolling: touch; // ansonsten ist das ux absolut steif. wieder was gelernt :)
		scrollbar-width: none !important; /* Firefox */
		-ms-overflow-style: none !important;  /* IE 10+ */
		
		&::-webkit-scrollbar {
			width: 0px !important;
		}
	}
	.fadeOut {	// das element kommt immer unten an die scroller dran um durch das ausfaden ein "mehr an conten" zu visualisieren
		position: absolute;
		left: 0; right: 0;
		bottom: 0;
		height: 2em;
		background-image: linear-gradient(rgba(255, 255, 255, 0), white);
	}
	.noisy {
		//background-image: url(http://api.thumbr.it/whitenoise-361x370.png?background=eeeeee&noise=626262&density=15&opacity=76);
		//background-color: fade(black, 20);
	}
	.devPost {
		border: 1px solid fade(black, 35);		
		padding: 0.5em;
		margin: 0em 0;
		font-weight: bold;	
		background-color: fade(black, 5);
		
		a:before { content: ""; }
		
		@borderRadius: 0.25em;
		
		&:first-of-type {
			//margin-top: 0.5em;
			border-top-left-radius: @borderRadius;
			border-top-right-radius: @borderRadius;			
		}
		&:last-of-type {
			//margin-bottom: 0.5em;
			border-bottom-left-radius: @borderRadius;
			border-bottom-right-radius: @borderRadius;
		}
			
		& + & {
			//margin-top: -1px;
			border-top: none;			
		}
	}
	.debugOutline {
		@color: red;
		
		position: relative;
		outline: 1px solid @color;
		outline-offset: -1px;
		
		// label
		&[data-debugName]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: @color;
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: white;
			padding: 0.25em 0.5em;
			font-weight: bold;
			content: attr(data-debugName);
		}
	
		// change color for nested elements
		& .debugOutline {
			@color: darken(orange, 10);	
			background-color: fade(@color, 20);
			outline-color: @color;
			&:after { background-color: @color; }
		}	
	
	}
	.hSpace {
		position: relative;
		
		&--small {
			padding-left: 0.75em;
			padding-right: 0.75em;	
			
			[showborders1] &:after {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-left: 0.75em solid fade(cyan, 60);
				border-right: 0.75em solid fade(cyan, 60);
				pointer-events: none;
				content: "";
			}
		}
		&--medium {
			padding-left: 1.5em;
			padding-right: 1.5em;
			
			[showborders1] &:after {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-left: 1.5em solid fade(blue, 20);
				border-right: 1.5em solid fade(blue, 20);
				pointer-events: none;
				content: "";
			}			
		}
		&--big {
			padding-left: 2em;
			padding-right: 2em;
			
			[showborders1] &:after {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-left: 2em solid fade(green, 30);
				border-right: 2em solid fade(green, 30);
				pointer-events: none;
				content: "";
			}
		}
		&--notLeft {
			padding-left: 0px;
			
			[showborders1] &:after {
				border-left-width: 0px;
			}			
		}
		&--notRight {
			padding-right: 0px;
			
			[showborders1] &:after {
				border-right-width: 0px;
			}			
		}
		
		@media @mediaQuery__sm {
			&--small,
			&--medium,
			&--big {
				padding-left: 12px;
				padding-right: 12px;
				
				[showborders1] &:after {
					border-left-width: 12px;
					border-right-width: 12px;
					border-left-color: fade(green, 30);
					border-right-color: fade(green, 30);
				}
			}
			&--notLeft {
				padding-left: 0px;
				
				[showborders1] &:after {
					border-left-width: 0px;
				}			
			}
			&--notRight {
				padding-right: 0px;
				
				[showborders1] &:after {
					border-right-width: 0px;
				}			
			}			
		}		
	}
	.vSpace {
		position: relative;
		
		&--default {
			padding-top: 1.22em;
			padding-bottom: 1.22em;	
			
			[showborders2] &:before {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-top: 1.22em solid fade(pink, 40);
				border-bottom: 1.22em solid fade(pink, 40);
				pointer-events: none;
				content: "";
			}						
		}
		&--appHeader {
			padding-top: 1.2em;
			padding-bottom: 1em;					
			
			[showborders2] &:before {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-top: 1.2em solid fade(pink, 20);
				border-bottom: 1em solid fade(pink, 20);
				pointer-events: none;
				content: "";
			}			
		}
		&--projectTitlePost,
		&--newsPost {
			padding-top: 1.5em;
			padding-bottom: 1.5em;
			
			[showborders2] &:before {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-top: 1.5em solid fade(orange, 40);
				border-bottom: 1.5em solid fade(orange, 40);
				pointer-events: none;
				content: "";
			}					
		}
		&--postTeaser {
			padding-top: 0.65em;
			padding-bottom: 2.5em;
			
			[showborders2] &:before {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-top: 0.65em solid fade(red, 40);
				border-bottom: 2.5em solid fade(red, 40);
				pointer-events: none;
				content: "";
			}			
		}
		&--pageContent {
			padding-top: 2em;
			padding-bottom: 2em;	
			
			[showborders2] &:before {
				position: absolute;
				top: 0; left: 0;
				bottom: 0; right: 0;
				border-top: 2em solid fade(brown, 35);
				border-bottom: 2em solid fade(brown, 35);
				pointer-events: none;
				content: "";
			}
		}
		&--notBottom {
			padding-bottom: 0;
			
			[showborders2] &:before {
				border-bottom: none;
			}
		}

		@media @mediaQuery__sm {
			
			&--postTeaser,
			&--projectTitlePost,
			&--appHeader,
			&--default {
				padding-top: 12px;
				padding-bottom: 12px;
				
				[showborders1] &:before {
					border-top-width: 12px;
					border-bottom-width: 12px;
					border-top-color: fade(orange, 35);
					border-bottom-color: fade(orange, 35);
				}
			}
			&--newsPost,
			&--pageContent {
				padding-top: 12px;
				padding-bottom: 17px;
				
				[showborders1] &:before {
					border-top-width: 12px;
					border-bottom-width: 17px;
					border-top-color: fade(orange, 35);
					border-bottom-color: fade(brown, 35);
				}
			}
			&--notBottom {
				padding-bottom: 0;
				
				[showborders2] &:before {
					border-bottom: none;
				}
			}			
		}
	}
	.border {
		&--left {
			border-left: 1px solid;
		}
		&--right {
			border-right: 1px solid;
		}
		&--bottom {
			// 2019-06-03  	Es gab einen Bug beim Ausklappen der Filter: Manchmal verschwand
			// 				die Linie. Das hängt sehr vermutlich mit einem Renderproblem zusammen, 
			// 				da ich teilweise mit halben Pixel arbeiten musste. 
			// 				Da Bug war auf einem Windows Chrome aufgefallen, trat allerdings 
			// 				auch bei mir auf meinem Mac Chrome auf. Das Setzen der border-width 
			// 				auf 1.1px hat bei mir auf dem Mac das Problem gelöst.
			border-bottom: 1.1px solid;	
		}
		&--top {
			border-top: 1px solid;
		}
	}
	.row {
		&--green {
			background-color: @swatch__green;
		}
		&--yellow {
			background-color: @swatch__yellow;
		}
		&--red {
			background-color: @swatch__red;
		}
	}
	.noFlexGrow {
		//background-color: fade( red, 20 );
		
		flex-grow: 0 !important;
	}
	.handleBold {	// disable bold fonts
		b, strong {
			font-weight: normal;
		}
	}
	.appTitle {
		
		.routerLink {
			color: inherit;
			text-decoration: none;
		}
		
		&__text {
			white-space: nowrap;
		}
		&__text--full {
			display: inline;
		}
		&__text--short {
			display: none;
		}
		&__dot {
			display: inline-block;
			background-color: black;
			height: 0.8em;
			width: 0.8em;
			border-radius: 50%;
			margin: 0 0.3em;
			transform: translateY(.05em);
		}
		
		@media (max-width: 1285px) { // handle short and full page title
			&__text--full {
				display: none;
			}
			&__text--short {
				display: inline;
			}
		}
		@media @mediaQuery__sm {			
			&__dot {
				margin-left: 0;
			}
		}
	}
	.splashScreen {
		position: fixed;
		top: 0; left: 0;
		width: 100vw; height: 100vh;
		z-index: 100;
		display: block;
		
		background-color: white;
		cursor: pointer;
		
		&--hasTransition {
			transition: all 0.85s ease;			
		}
		&--isHidden {
			//transform: translateX(-100%);
			left: -100vw;
		}
		
		&__title {
			//background-color: red;
			position: absolute;
			top: 0; left: 0;			
		}
		&__titleText {
			mix-blend-mode: difference;
			color: white;
		}
		&__titleDot {
			display: inline-block;
			height: 0.8em;
			width: 0.8em;
			margin-right: 0.25em;
			border-radius: 50%;
			transform: translateY(.05em);
			mix-blend-mode: difference;
			background-color: white;
		}
		&__image {
			position: absolute;
			top: 0; left: 0;
			width: inherit; height: inherit;
			cursor: pointer;
			background-color: white;
		}	
		
		.mhImage__image	{
			width: 100vw; height: 100vh !important;
			background-size: cover !important;
		}
	
		@media @mediaQuery__sm {
			
			&__titleDot,
			&__titleText {
				font-size: 20px;
				line-height: 23px;
			}
			
			&--isHidden {
				top: -100vh;
				left: 0;
			}
			
		}
	
	}
	.noBreak {
		white-space: nowrap;
	}
	.toggleContent {
		
		&__btn {
			display: none;
		}
		
		// only visible on small screens
		@media @mediaQuery__sm {
		
			&__btn {				
				[showborders1] & { 
					background-color: fade(red, 20); 
				}
				
				width: 100%; height: 100%;
				display: flex;
				justify-content: flex-end;
				cursor: pointer;
				
				&:hover {
					text-decoration: none !important;
				}
				&:after {	// enlarge for better ux
					//background-color: fade(blue, 20);
					position: absolute;
					right: 0; top: 0; bottom: 0;
					width: 100vw;
					content: "";
					cursor: inherit;
				}
				&:before {
					position: relative;
					display: inline-block;
					text-align: right;
					width: 0.75em;
					content: "+" !important;
					transform: translateY(-0.1em);
				}
				
				&--isActive {
					&:before {
						content: "×" !important;
					}
				}		
				
			}
			&__content {
				height: 0;
				overflow: hidden;
				margin-top: -1px;
			}
		
		}
		
	}
	.viewTitle {	// title row for mobile
		//background-color: fade(red, 20);
		
		@indent__left: 0.75em;
		@icon__width: 1.5em;
		&__searchWrapper {
			position: relative;
		}
		&__searchInput {
			width: 100%;
			padding-left: @icon__width;
			border: 0; 
			outline: 0;
		}
		&__searchIcon {
			//background-color: red; 
			
			position: absolute;
			top: 0; left: 0; bottom: 0;
			background-image: url('@/../assets/imgs/search-icon.svg');
			width: @icon__width;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: 0.85em;
			pointer-events: none;
		}
	}

	.app {
		position: fixed;
		top: -1px; left: 0; // damit der border-bottom des geschlossenen headers versteckt ist
		bottom: 0; right: 0;
		display: flex;
		flex-direction: column;	
	}
	.app__header {
		position: relative;
		display: block;
		flex-shrink: 0;
		
		//-webkit-transform-style: preserve-3d;
		//-webkit-backface-visibility: hidden;
		//-webkit-transform: translate3d(0, 0, 0);
		&Inner {		
			display: flex;
			flex-grow: 1;
			justify-content: space-between;		
			overflow: hidden;
		}
		
	}
	.app__toggleHeaderBtn {
		//background-color: red;
		position: fixed;
		top: 0; right: 0;
		z-index: 50;
		display: block;
		//padding-left: 1em;
		padding-right: 1.5em;
		cursor: pointer;
		
		@height: .7em;
		@width: 1.5em;
		
		div {
			//background-color: yellow;
			margin-top: 0.2em;
			margin-bottom: 0em;
			height: @height;
			width: @width;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		span {
			height: 1px;
			width: 100%;
			display: block;
			background-color: black;
		}
	
		@media @mediaQuery__sm {
			right: -0.5em;
		}
	
	}
	.app__body {
		//background-color: fade(green, 20);
		//overflow: auto;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		
		//background-color: fade(magenta, 70);
		-webkit-overflow-scrolling: touch; // ansonsten ist das ux absolut steif. wieder was gelernt :)
		overflow-x: hidden; // ansonsten kann man die Templates für Lehre und Projekte auch seitlich scrollen
		overflow-y: scroll;
	}
		.app__content {
			//background-color: fade(green, 30);
			//padding: 1em;
			flex-grow: 1;			
		}
		.app__footer {
			.luhLogoCol {
				//background-color: fade(yellow, 30);
				padding-left: 0;
				display: flex;
				align-items: flex-end;
				
				&__logo {
					//background-color: fade(yellow, 30);
					height: 3.1em;
					width: 100%;
					background-size: contain;
					background-position: right top;
					background-repeat: no-repeat;			
					background-image: url('@/../assets/imgs/luh-logo.svg');				
				}
			}
		}

	@media @mediaQuery__sm {
		.app__header {
			//display: none;
		}
		.app__footer {
			.col {
				padding-bottom: 0;				
			}
			.col::before {
				border-bottom-width: 0;
			}
			.luhLogoCol {
				padding-top: 0;				
				margin-top: -3.1em;
				margin-bottom: 1.35em;
			}
			.luhLogoCol::before {
				border-top-width: 0;
			}
		}
	}

</style>
