import Vue from 'vue'
import Router from 'vue-router'
import HomeView from './views/HomeView.vue'

Vue.use(Router)

export default new Router({
	base: '/',
	mode: 'history',
	linkActiveClass : 'routerLink--active',
	linkExactActiveClass : 'routerLink--exactActive',
	routes: [
		{
			path: '/:lang/',
			name: 'HomeView',
			component: HomeView
		},
		{
			path: '/:lang/news/:postSlug',
			name: 'HomeView--scrollTo',
			component: HomeView
		},
		{
			path: '/:lang/restApi/',
			name: 'RestApiView',
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () =>
				import ( /* webpackChunkName: "RestApiView" */ './views/RestApiView.vue')
		},
		{
			path: '/:lang/team/',
			name: 'TeamView',
			component: () =>
				import ( /* webpackChunkName: "TeamView" */ './views/TeamView.vue')
		},
		{
			path: '/:lang/team/:postSlug/',
			name: 'TeamView--scrollTo',
			component: () =>
				import ( /* webpackChunkName: "TeamView" */ './views/TeamView.vue')
		},
		{
			path: '/:lang/publications/',
			name: 'PublicationsView',
			component: () =>
				import ( /* webpackChunkName: "PublicationsView" */ './views/PublicationsView.vue')
		},
		{
			path: '/:lang/publications/:postSlug/',
			name: 'PublicationsView--scrollTo',
			component: () =>
				import ( /* webpackChunkName: "PublicationsView" */ './views/PublicationsView.vue')
		},		
		{
			path: '/:lang/programms/',
			name: 'ProjectsView-programm_post',
			meta: { 
				postType: 'programm_post'
			},
			component: () =>
				import ( /* webpackChunkName: "ProjectsView" */ './views/ProjectsView.vue')
		},
		{
			path: '/:lang/programms/:postSlug/',
			name: 'ProgrammDetailView',
			component: () =>
				import ( /* webpackChunkName: "ProgrammDetailView" */ './views/ProgrammDetailView.vue')
		},
		{
			path: '/:lang/research/',
			name: 'ResearchView',
			component: () =>
				import ( /* webpackChunkName: "ResearchView" */ './views/ResearchView.vue')
		},
		{
			path: '/:lang/research/:postSlug/',
			name: 'ResearchView--scrollTo',
			component: () =>
				import ( /* webpackChunkName: "ResearchView" */ './views/ResearchView.vue')
		},
		{
			path: '/:lang/projects/',
			name: 'ProjectsView-project_post',
			meta: { 
				postType: 'project_post'
			},			
			component: () =>
				import ( /* webpackChunkName: "ProjectsView" */ './views/ProjectsView.vue')
		},
		{
			path: '/:lang/projects/:postSlug/',
			name: 'ProjectDetailView',
			component: () =>
				import ( /* webpackChunkName: "ProjectDetailView" */ './views/ProjectDetailView.vue')
		},
		{
			path: '/:lang/search/',
			name: 'SearchView-Empty',
			component: () =>
				import ( /* webpackChunkName: "searchView" */ './views/SearchView.vue')
		},
		{
			path: '/:lang/search/:searchString/',
			name: 'SearchView',
			component: () =>
				import ( /* webpackChunkName: "searchView" */ './views/SearchView.vue')
		},
		{
			path: '/:lang/mhImage/',
			name: 'MhImageView',
			component: () =>
				import ( /* webpackChunkName: "MhImageView" */ './views/MhImageView.vue')
		},
		{
			path: '/:lang/:pageSlug/',
			name: 'PageView',
			component: () =>
				import ( /* webpackChunkName: "PageView" */ './views/PageView.vue')
		},
		{
			path: "*", 
			name: 'NotFoundView',
			component: () =>
				import ( /* webpackChunkName: "NotFoundView" */ './views/NotFoundView.vue')			
		}
	]
})
