<template>
	<div class="mainNav hSpace hSpace--big vSpace vSpace--appHeader">
		<template v-if="$parent.acfOptions">
			<div class="mainNav__row mainNav__row--firstNavRow">
				
				<router-link 
					class="routerLink noArrow"
					:class="mainNavLinkClasses( menuItem )"
					v-for="(menuItem, index) in $parent.acfOptions.mainMenu__row1" 
					:to="mainNavGetTo2( menuItem )"
					:data-type="menuItem.type"
					:key="index"
					v-on:click.native="clickMainNavLink( 'row1', menuItem )">
					{{$root.getTranslatedAcfValue( menuItem, 'title' )}}
				</router-link>
				
				<template v-if="$parent.acfOptions.showLangToggle">
					<template v-if="$root.getCurrentLang()=='de'">
						<a class="changeLangBtn font font--uppercase" @click="changeLang('en')" role="button">EN</a> 
					</template>
					<template v-if="$root.getCurrentLang()=='en'">
						<a class="changeLangBtn font font--uppercase" @click="changeLang('de')" role="button">DE</a> 
					</template>
				</template>
				
				<a class="toggleSearchBarBtn" @click="toggleSearchBar()"></a> 
				
			</div>
			<div class="mainNav__row mainNav__row--secondNavRow" v-show-slide="secondRowIsVisible">
				
				<span class="routerLink routerLink--fakeIndent">
					{{$root.getTranslatedAcfValue( $parent.acfOptions.mainMenu__row1[0], 'title' )}}
				</span>
				
				<router-link 
					class="routerLink noArrow"
					:class="mainNavLinkClasses( menuItem )"
					v-for="(menuItem, index) in $parent.acfOptions.mainMenu__row2" 
					:to="mainNavGetTo2( menuItem )"
					:data-type="menuItem.type"
					:key="index"
					v-on:click.native="clickMainNavLink( 'row2', menuItem )"
					>
					{{$root.getTranslatedAcfValue( menuItem, 'title' )}}
				</router-link>
				
			</div>
			<div class="mainNav__row mainNav__row--searchRow" v-show-slide="searchBarIsVisible">				
				<div class="mainNav__searchBar">
					<input 
						class="mainNav__searchInput" 
						type="text"
						:value="$route.params.searchString" 
						autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
						@keyup.enter="switchToSearchView( $event.target.value )" />
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	// @ is an alias to /src 
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'MainNav',
		components: {},			
		props: {},
		data() {
			return {
				secondRowIsVisible : false,	
				searchBarIsVisible : false,			
			}
		},
		watch: {
			searchBarIsVisible (to, from){	// focus searchInput on show
				const searchInput = document.querySelector('.mainNav__searchInput')
				
				if(to){
					searchInput.focus()
				}else{
					searchInput.blur()					
				}
			}
		},		
		computed: {},
		methods: {
			clickMainNavLink( rowName, menuItem ){
				this.searchBarIsVisible = false
				if( 'row1' == rowName ){
					if( 'toggleSecondRow' == menuItem.type ){
						this.secondRowIsVisible = !this.secondRowIsVisible
					}else{
						this.secondRowIsVisible = false
					}
				}
			},
			mainNavGetTo2( menuItem ){
				var currentLang = this.$root.getCurrentLang()
				var returnString = ''
				
				if( 'homeView' == menuItem.type ) returnString = '/' + currentLang + '/'
				if( 'toggleSecondRow' == menuItem.type ) returnString = ''
				if( 'programmsView' == menuItem.type ) returnString = '/' + currentLang + '/programms/'
				if( 'researchView' == menuItem.type ) returnString = '/' + currentLang + '/research/'
				if( 'archiveView' == menuItem.type ) returnString = '/' + currentLang + '/projects/'
				if( 'teamView' == menuItem.type ) returnString = '/' + currentLang + '/team/'
				if( 'publicationsView' == menuItem.type ) returnString = '/' + currentLang + '/publications/'				
				if( 'pageView' == menuItem.type ) returnString = '/' + currentLang + '/' + menuItem.page.post_name + '/'
				
				// TODO:40 remove, its just for dev
				if( 'pageView' == menuItem.type && menuItem.page.post_type == 'programm_post') 
					returnString = '/' + currentLang + '/programms/' + menuItem.page.post_name + '/'
				if( 'pageView' == menuItem.type && menuItem.page.post_type == 'project_post') 
					returnString = '/' + currentLang + '/projects/' + menuItem.page.post_name + '/'
				
				return returnString
				
			},
			mainNavIsActive2( menuItem ){
				return false;
			},
			mainNavLinkClasses( menuItem ){
				let classes = []
				
				// programms sollen auch aktiv sein, wenn eine detailseite aufgerufen wurde
				if( 'programmsView' == menuItem.type && this.$route.path.indexOf('/programms/') != -1){
					classes.push('routerLink--isActive')
					//console.log( this.$route )
				}
				// programms sollen auch aktiv sein, wenn eine detailseite aufgerufen wurde
				if( 'archiveView' == menuItem.type && this.$route.path.indexOf('/projects/') != -1){
					classes.push('routerLink--isActive')
				}
				// info soll aktiv sein, wenn die second bar geöffnet ist
				if( 'toggleSecondRow' == menuItem.type && this.secondRowIsVisible ){
					classes.push('routerLink--isActive')
				}
				
				return classes
			},	
			changeLang( newLang ){
				this.$router.push({params: { lang: newLang }})
			},
			toggleSearchBar() {
				this.secondRowIsVisible = false
				this.searchBarIsVisible = !this.searchBarIsVisible
			},
			switchToSearchView( searchString ) {
				console.log('%cMainNav switchToSearchView()', 'font-weight: bold; margin-top: 1em;')
				console.log('	searchString: ' + searchString)
				
				if( searchString ){
					this.$router.push({ 
						name: 'SearchView', 
						params: { 
							//	2019-06-03  Ich weiss nicht mehr warum encodeURI() drin war,
							//				auf alle Fälle verursacht die einen Fehler, wenn
							//				man nach strings mit Leerzeichen sucht, z.B.
							//				input:	mario helbing
							//				url:	... de/search/mario%2520helbing
							//				titel:	Suchergebnisse für: mario%20helbing
							//				
							//				Ich habe die Funktion also wieder entfernt, jetzt klappt's.
							//				
							//searchString : encodeURI(searchString) 
							searchString : searchString	
						}
					})					
				}else{
					this.$router.push({ 
						name: 'SearchView-Empty',
					})					
				}

			}
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less" scoped>
	.mainNav {
		position: relative;
		
		.mainNav__row {
			display: flex;
			padding-right: 3em;
		}
		.mainNav__row--firstNavRow {
			margin-bottom: 0.2em;
		}
		
		.routerLink {
			display: inline-block;
			margin-right: 2em;
			color: inherit;
			text-decoration: none;
			white-space: nowrap;
		}
		.routerLink[data-type="toggleSecondRow"] {
			text-decoration: none;
		}		
		.routerLink[data-type="toggleSecondRow"].routerLink--isActive {
			text-decoration: underline;							
		}		
		.routerLink[data-type="programmsView"].routerLink--isActive {
			text-decoration: underline;							
		}		
		.routerLink[data-type="archiveView"].routerLink--isActive {
			text-decoration: underline;							
		}		
		.routerLink:hover,
		.routerLink--exactActive {
			text-decoration: underline;				
		}
		.routerLink--fakeIndent {
			pointer-events: none;
			color: transparent;
		}
		.toggleSearchBarBtn {
			//background-color: red;
			background-image: url('@/../../assets/imgs/search-icon.svg');
			height: 1em; width: 1.1em;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transform: translateY(0.1em);
		}		
		
		// link margins
		.routerLink,
		.changeLangBtn {		
			margin-right: 2em;			
		}
		// 2019-05-25  Lang switch button wird erstmal ausgeblendet bis das englische ausreichend befüllt ist
		// 2019-09-30  Das Ein/Ausblenden wird jetzt über ein ACF-Option gelöst
		// .changeLangBtn { display: none; }
		
		// enlarge link for better ux
		@enlargeBy: 0.5em;		
		.routerLink,
		.changeLangBtn,
		.toggleSearchBarBtn {
			position: relative;
			
			&:before {
				//background-color: fade(yellow, 50);
				position: absolute;
				top: 0; bottom: 0;
				//top: -@enlargeBy; bottom: -@enlargeBy; 
				left: -@enlargeBy; right: -@enlargeBy;
				content: "";
			}
		}
		
		.mainNav__searchBar {
			width: 100%;
		}
		.mainNav__searchInput {
			width: 100%;
			outline: none;
			border: none;
			border-bottom: 1px solid black;
		}
		
	}
</style>
