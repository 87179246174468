<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>	
		
		<div class="newsPosts border border--bottom row"  :style="wrapperElmStyles"> 		
			
			<MhImage	class="newsPosts__teaserImage border border--right col col--5of12 col--sm-0of12" 
						:imageObject="image"
						backgroundPosition="top center"
						mode="cover" 
						v-if="!$root.isSmallDevice"></MhImage>

			<div 		class="newsPosts__posts col col--7of12 col--sm-12of12">
				
				<div class="newsPosts__postsScroller hideScrollbars">
					<div 	class="newsPosts__post border border--bottom" 
						@mouseover="image = post.acf.image" 
						v-for="post in posts" 
						:id="post.slug"
						:key="post.id">
					
					<MhImage	class="newsPosts__postImage" 
								:imageObject="post.acf.image" 
								mode="img" 
								v-if="$root.isSmallDevice"></MhImage>
					
					<div class="newsPosts__postText vSpace vSpace--newsPost hSpace hSpace--big">
						<h2 class="newsPosts__postHeader">
							<span class="newsPosts__postDate font font--sizeHuge">{{$root.getTranslatedAcfValue( post.acf, 'date')}}</span>
							<span class="newsPosts__postTitle font font--sizeHuge">{{$root.getTranslatedAcfValue( post.acf, 'postTitle')}}</span>
							<span class="newsPosts__postRubrik font font--sizeSmall font--uppercase">{{$root.getTranslatedAcfValue( post.acf, 'rubrik')}}</span>
						</h2>
						<div class="newsPosts__postBody" v-html="$root.getTranslatedAcfValue( post.acf, 'text')"></div>
					</div>
					
				</div>
				</div>
				<div class="fadeOut"></div>
			
			</div>
		
		</div> 
	
	</MhView>
</template>

<script>
	// @ is an alias to /src	
	import MhImage from '@/components/MhImage/MhImage.vue'
	import MhView from '@/components/MhView/MhView.vue'
	//import MhScroller from '@/components/MhScroller/MhScroller.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import { EventBus } from '@/event-bus.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'homeView',
		components: {
			MhImage,
			MhView,
			//MhScroller,
			MobilePageTitle,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {
		},
		data() {
			return {
				isLoading: true,
				posts: [],
				image: null,
				//appHeaderHeight : 0 // wird via EventBus.$on('appHeaderResized') gesetzt		
			}
		},
		computed: {
			wrapperElmStyles() {
				const appHeaderHeight = this.$root.appHeaderHeight
				const elmHeight = 'calc(100vh - ' + appHeaderHeight + 'px + 1px)'
				
				if( !this.$root.isSmallDevice ){
					return {
						transition : 'all 0.1s linear',
						height     : elmHeight,
						maxHeight  : elmHeight,
					}
				}else{
					return {}
				}				
			}
		},
		methods: {
			handleScroll() {
				console.log(123)
			}
		},
		mounted() {
			
			// fetch newsPosts
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/wp/v2/newsPosts',
				params : {},
				callback : (response) => {
					this.posts = response.data
					this.image = this.posts[0].acf.image
					this.isLoading = false
					
					// wenn man von der suche kommt ist der slug mit in der url,
					// und zu dem soll gleich gescrollt werden					
					if( this.$route.params.postSlug ){
						setTimeout( () => {
							const element = document.getElementById( this.$route.params.postSlug );
							element.scrollIntoView( {block: "start", behavior: "smooth"} )
						}, 150)
					}					
				},
			})			
			
			// post container ist zusammen mit der header height 100vh
			// wenn sich die header höhe ändert, wird die neue height 
			// hier via event empfangen und dient der berechnung der 
			// höhe des post containers
			/*
			EventBus.$on('appHeaderResized', elmHeight => {
				this.appHeaderHeight = elmHeight				
				//console.log( elmHeight )
			})
			*/
			
		},
	}
</script>

<style lang="less" scoped>
	@import "../less/includes/grid.include.less";
	
	.newsPosts {
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0px;
		overflow: hidden;
	}
	
	.newsPosts__posts {
		position: relative;
		height: 100%;
	}
	.newsPosts__postsScroller {
		overflow-x: auto;	
		height: inherit;
	}
	.newsPosts__postDate {
		display: inline-block;
		padding-right: 1em;
		
		&:empty { display: none; }
	}
	.newsPosts__postTitle {
		padding-right: 1em;		
	}
	.newsPosts__postBody {
		padding-top: 1em;
	}

	@media @mediaQuery__sm {
		.newsPosts {
			position: relative;
			height: auto;
		}
		.newsPosts__post:last-child {
			border-bottom: none;
		}		
	}
</style>
