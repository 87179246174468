<template>
	<h1 class="mobilePageTitle appTitle hSpace hSpace--big vSpace vSpace--default border border--bottom">
		<a 	href="/de/" 
			class="routerLink routerLink--exactActive routerLink--active noArrow">
			<span class="appTitle__text">
				Institut für Entwerfen und Städtebau
			</span><br>
			<span class="appTitle__dot"></span>
			<span class="appTitle__text">
				Städtebauliches Entwerfen
			</span>
		</a>
	</h1>
</template>

<script>
	// @ is an alias to /src 
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'mobilePageTitle',
		components: {
		},			
		props: {
			//imageObject: [Object, Boolean],
		},
		data() {
			return {				
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {											
				},
				deep: true,
			}			
		},		
		computed: {			
		},
		methods: {						
		},
		created() {
		},
		mounted() {			
		},
	}
</script>

<style lang="less">
	.mobilePageTitle {
	}
</style>
