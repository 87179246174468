<template>
	<div class="mobileNav border border--right" :class="{'mobileNav--isVisible' : $parent.mobileNavIsVisible}">
		<template v-if="$parent.acfOptions">
			
			<div class="appTitle hSpace hSpace--big vSpace vSpace--default border border--bottom">
				<router-link class="routerLink" 
					:to="'/'+$root.getCurrentLang()+'/'"
					v-on:click.native="$parent.mobileNavIsVisible = false">
					<span class="appTitle__text">Institut für Entwerfen und Städtebau</span><br />
					<span class="appTitle__dot"></span>
					<span class="appTitle__text">Städtebauliches Entwerfen</span>
				</router-link>	
			</div>				
			
			<ul class="mobileNav__menuList">
				<li class="mobileNav__menuListItem border border--bottom"
					v-for="(menuItem, menuItemIndex) in $parent.acfOptions.mainMenu__row1" :key="menuItemIndex">
					<router-link 
						class="mobileNav__menuListLink routerLink noArrow hSpace hSpace--big vSpace vSpace--default"
						:class="mainNavLinkClasses( menuItem )"						
						:to="mainNavGetTo2( menuItem )"
						:data-type="menuItem.type"
						:key="menuItemIndex"
						v-on:click.native="clickMainNavLink( 'row1', menuItem )">
						{{$root.getTranslatedAcfValue( menuItem, 'title' )}} 
					</router-link>
					<template v-if="secondRowIsVisible">
						<router-link 
							class="mobileNav__menuListLink mobileNav__menuListLink--secondLevel routerLink noArrow"
							v-for="(menuItem2, index2) in $parent.acfOptions.mainMenu__row2"
							v-if="menuItem.type == 'toggleSecondRow'"
							:class="mainNavLinkClasses( menuItem2 )"						
							:to="mainNavGetTo2( menuItem2 )"
							:data-type="menuItem2.type"
							:key="'snd-'+index2"
							v-on:click.native="clickMainNavLink( 'row1', menuItem2 )">
							{{$root.getTranslatedAcfValue( menuItem2, 'title' )}} 
						</router-link>							
					</template>						
				</li>
				
				<li class="mobileNav__menuListItem mobileNav__menuListItem--langSwitch border border--bottom" v-if="$root.getCurrentLang()=='de'">
					<a class="mobileNav__menuListLink mobileNav__menuListLink--langSwitchBtn hSpace hSpace--big vSpace vSpace--default font font--uppercase" @click="changeLang('en')">EN</a> 
				</li>
				<li class="mobileNav__menuListItem mobileNav__menuListItem--langSwitch border border--bottom" v-if="$root.getCurrentLang()=='en'">
					<a class="mobileNav__menuListLink mobileNav__menuListLink--langSwitchBtn hSpace hSpace--big vSpace vSpace--default font font--uppercase" @click="changeLang('de')">DE</a> 
				</li>			
				
				<li class="mobileNav__menuListItem border border--bottom">
					<div class="mobileNav__search hSpace hSpace--big vSpace vSpace--default">
						<form @submit.prevent="switchToSearchView2( $event )"> 
							<input 
								class="mobileNav__searchInput" 
								type="text"
								:value="$route.params.searchString" 
								autocomplete="off" 
								autocorrect="off" 
								autocapitalize="off" 
								spellcheck="false"
								/>
						</form>
						<div class="mobileNav__searchIcon"></div>
						<br />
					</div>
				</li>			
			</ul>
			
			<a 	class="mobileNav__closeBtn hSpace--big hSpace--notLeft vSpace vSpace--default vSpace--notBottom" 
				@click="$parent.mobileNavIsVisible = false" role="button">
				<div>
					<span></span>
					<span></span>					
				</div>
			</a>			
			
		</template>
	</div>
</template>

<script>
	// @ is an alias to /src 
	//import DevInfos from '@/components/DevInfos.vue'

	export default {
		name: 'MobileNav',
		components: {},			
		props: {},
		data() {
			return {
				secondRowIsVisible : false,	
				searchBarIsVisible : false,			
			}
		},
		watch: {
			/*
			searchBarIsVisible (to, from){	// focus searchInput on show
				const searchInput = document.querySelector('.mainNav__searchInput')
				
				if(to){
					searchInput.focus()
				}else{
					searchInput.blur()					
				}
			}
			*/
		},		
		computed: {},
		methods: {
			clickMainNavLink( rowName, menuItem ){
				this.searchBarIsVisible = false

				if( 'toggleSecondRow' == menuItem.type ){
					this.secondRowIsVisible = !this.secondRowIsVisible
				}else{
					this.$parent.mobileNavIsVisible = false
				}
			},
			mainNavGetTo2( menuItem ){
				var currentLang = this.$root.getCurrentLang()
				var returnString = ''
				
				if( 'homeView' == menuItem.type ) returnString = '/' + currentLang + '/'
				if( 'toggleSecondRow' == menuItem.type ) returnString = ''
				if( 'programmsView' == menuItem.type ) returnString = '/' + currentLang + '/programms/'
				if( 'researchView' == menuItem.type ) returnString = '/' + currentLang + '/research/'
				if( 'archiveView' == menuItem.type ) returnString = '/' + currentLang + '/projects/'
				if( 'teamView' == menuItem.type ) returnString = '/' + currentLang + '/team/'
				if( 'publicationsView' == menuItem.type ) returnString = '/' + currentLang + '/publications/'				
				if( 'pageView' == menuItem.type ) returnString = '/' + currentLang + '/' + menuItem.page.post_name + '/'
				
				// TODO:40 remove, its just for dev
				if( 'pageView' == menuItem.type && menuItem.page.post_type == 'programm_post') 
					returnString = '/' + currentLang + '/programms/' + menuItem.page.post_name + '/'
				if( 'pageView' == menuItem.type && menuItem.page.post_type == 'project_post') 
					returnString = '/' + currentLang + '/projects/' + menuItem.page.post_name + '/'
				
				return returnString
				
			},
			mainNavIsActive2( menuItem ){
				return false;
			},
			mainNavLinkClasses( menuItem ){
				let classes = []
				
				if( 'toggleSecondRow' == menuItem.type && this.secondRowIsVisible ){
					classes.push('routerLink--isActive')
				}
				
				return classes
			},	
			changeLang( newLang ){
				this.$parent.mobileNavIsVisible = false
				
				this.$router.push({params: { lang: newLang }})
			},
			toggleSearchBar() {
				this.secondRowIsVisible = false
				this.searchBarIsVisible = !this.searchBarIsVisible
			},
			switchToSearchView2( $event ) {
				
				const searchInput  = document.querySelector('.mobileNav__searchInput')
				const searchString = searchInput.value
				
				console.log('%cMainNav switchToSearchView()', 'font-weight: bold; margin-top: 1em;')
				console.log('	searchString: ' + searchString)
				
				this.$parent.mobileNavIsVisible = false
				
				if( searchString ){
					this.$router.push({ 
						name: 'SearchView', 
						params: { 
							searchString : encodeURI(searchString) 
						}
					})					
				}else{
					this.$router.push({ 
						name: 'SearchView-Empty',
					})					
				}
				
				searchInput.blur()
				
			},
			XXXswitchToSearchView( searchString, $event ) {
				console.log('%cMainNav switchToSearchView()', 'font-weight: bold; margin-top: 1em;')
				console.log('	searchString: ' + searchString)
				
				this.$parent.mobileNavIsVisible = false
				
				if( searchString ){
					this.$router.push({ 
						name: 'SearchView', 
						params: { 
							searchString : encodeURI(searchString) 
						}
					})					
				}else{
					this.$router.push({ 
						name: 'SearchView-Empty',
					})					
				}
				
				$event.preventDefault()

			}
		},
		created() {},
		mounted() {},
	}
</script>

<style lang="less" scoped>
	.mobileNav {
		
		@closeCross__heightWidth: 1.5em;
		
		transition: all 0.25s ease;
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		transform: translateX(-100%);
		background-color: white;
		z-index: 60;
		pointer-events: none;

		&--isVisible {
			transform: translateX(0%);
			pointer-events: auto;
		}		
		
		&__menuList {
			list-style: none;
		}
		&__menuListItem {
			display: block;
		}		
		// 2019-05-25  Lang switch button wird erstmal ausgeblendet bis das englische ausreichend befüllt ist
		// 2019-09-30  Das Ein/Ausblenden wird jetzt über ein ACF-Option gelöst
		// &__menuListItem--langSwitch { display: none; }
		&__menuListLink {
			text-decoration: none;
			color: inherit;	
			display: block;
			
			&--secondLevel {
				padding-bottom: 0.75em;
				padding-left: 3em;
			}
			&--secondLevel:last-child {
				padding-bottom: 1.1em;
			}
			&--langSwitchBtn {
				text-decoration: none !important;
			}
			
			&:hover, &.routerLink--exactActive {
				text-decoration: underline;				
			}								
			&[data-type="toggleSecondRow"] {
				text-decoration: none;
			}		
		}
		&__search {
			//background-color: fade(red, 10);
			
			@indent__left: 0.75em;
			@icon__width: 1.5em;
			
			&Icon {
				//background-color: red; 
				
				position: absolute;
				top: 0; left: @indent__left; bottom: 0;
				background-image: url('@/../../assets/imgs/search-icon.svg');
				width: @icon__width;
				background-repeat: no-repeat;
				background-position: left center;
				background-size: 0.85em;
				pointer-events: none;
			}			
			
			&Input {
				position: absolute;
				top: 0; left: 0; right: 0; bottom: 0;
				width: 100%;
				border: 0; 
				outline: 0;
				background-color: transparent;
				padding-left: @indent__left + @icon__width;
			}
		}
		&__closeBtn {
			//background-color: fade(red, 20);
			
			position: absolute;
			top: 0; right: 0;
			display: flex;
			
			div {
				position: relative;				
				height: @closeCross__heightWidth; 
				width: @closeCross__heightWidth;
			}
			span {
				position: absolute;
				top: 50%; left: 0; 
				background-color: black;
				height: 1px; width: 100%;
				display: block;
			}
			span:nth-child(1) {
				transform: rotate(45deg);
			}
			span:nth-child(2) {
				transform: rotate(135deg);
			}
		}
		
		// fixes: mobile safari zoom page if font-size is less 16px, 
		// see: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone/13918061#13918061
		input, textarea { 
			font-size: initial;
		}		
		
	}
</style>
